import { FunctionComponent, useEffect, useState } from "react";
import { IHistoryProps } from "./IHistory";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import DataGrid, {
  Column,
  Pager,
  Paging,
  LoadPanel,
} from "devextreme-react/data-grid";
import {
  Button,
  DateBox,
  SelectBox,
  TextBox,
  Popup,
  CheckBox,
} from "devextreme-react";
import moment from "moment";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import sBox from "devextreme/ui/select_box";
import dBox from "devextreme/ui/date_box";
import tBox from "devextreme/ui/text_box";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages } from "devextreme/localization";

loadMessages(lang);
locale("" + localStorage.getItem("lang"));

export const History: FunctionComponent<IHistoryProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [logs, setLogs] = useState([] as any);
  const [adminId, setAdminId] = useState("");
  const [eventType, setEventType] = useState("A");
  const [dateRangeState, setDateRangeState] = useState("D");
  const [endDateBoxState, setEndDateBoxState] = useState(false);
  const [startMinDate, setStartMinState] = useState<Date>(moment("2023-01-01").toDate());
  const [startMaxDate, setStartMaxState] = useState<Date>(moment("2050-12-31").toDate());
  const [endMinDate, setEndMinState] = useState<Date>(moment("2023-01-01").toDate());
  const [endMaxDate, setEndMaxState] = useState<Date>(moment("2050-12-31").toDate());
  const [currentPageSize, setCurrentPageSize] = useState(
    Number(sessionStorage.getItem('history_page_size')) || 10
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(
    Number(sessionStorage.getItem('history_page_index')) || 0
  );
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [NoC, setNoColumnState] = useState(true);
  const [AdminIdC, setAdminIdState] = useState(true);
  const [EventTypeC, setEventTypeState] = useState(true);
  const [DateC, setDateState] = useState(true);
  const [DescriptionC, setDescriptionState] = useState(true);

  const dateRangeTypes = [
    { id: 1, name: "Day", value: "D" },
    { id: 2, name: "Week", value: "W" },
    { id: 3, name: "Month", value: "M" },
  ];

  const eventTypes = [
    { id: 1, name: "ALL", value: "A" },
    { id: 2, name: "Login", value: "LOGIN" },
    { id: 3, name: "Logout", value: "LOGOUT" },
    { id: 4, name: "Login Failed", value: "LOGIN_FAILED" },
    { id: 5, name: "Password Changed", value: "PASSWORD_CHANGED" },
    { id: 6, name: "Password Expired", value: "PASSWORD_EXPIRED" },
    { id: 7, name: "Account Locked", value: "ACCOUNT_LOCKED" },
  ];


  useEffect(() => {
    if (sessionStorage.getItem("id")) {
      common.ResizePage();
      
      valToTag("startDate", dBox, moment());
      valToTag("endDate", dBox, moment());
      
      btnSearch("");
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const handleDateRangeChange = (e: any) => {
    const today = moment();
    
    if (e.value === "D") {
      setEndDateBoxState(false);
      valToTag("startDate", dBox, today.toDate());
      valToTag("endDate", dBox, today.toDate());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
      setDateRangeState("D");
    } else if (e.value === "W") {
      setEndDateBoxState(false);
      const weekAgo = today.clone().subtract(6, "days");
      _dev("startDate", dBox).option("value", weekAgo.toDate());
      _dev("endDate", dBox).option("value", today.toDate());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(today.toDate());
      setEndMinState(weekAgo.toDate());
      setEndMaxState(today.toDate());
      setDateRangeState("W");
    } else if (e.value === "M") {
      setEndDateBoxState(false);
      const firstDayOfMonth = today.clone().startOf("month");
      _dev("startDate", dBox).option("value", firstDayOfMonth.toDate());
      _dev("endDate", dBox).option("value", today.toDate());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(today.toDate());
      setEndMinState(firstDayOfMonth.toDate());
      setEndMaxState(today.toDate());
      setDateRangeState("M");
    }
  };

  const resetSearch = () => {
    setEndDateBoxState(false);
    setStartMinState(moment("2023-01-01").toDate());
    setStartMaxState(moment("2050-12-31").toDate());
    setEndMinState(moment("2023-01-01").toDate());
    setEndMaxState(moment("2050-12-31").toDate());
    setDateRangeState("D");
    valToTag("startDate", dBox, moment());
    valToTag("endDate", dBox, moment());
    valToTag("adminId", tBox, "");
    setEventType("A");
  };

  const valToTag = (e: any, box: any, value: any) => {
    const id = document.getElementById(e) as HTMLElement;
    const instance = box.getInstance(id);
    instance.option("value", value);
  };

  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  const funcSetEndDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    const startDate = _dev("startDate", dBox).option("value");
    if (dRangeVal === "W") {
      valToTag("endDate", dBox, moment(startDate).add(7, "days"));
    } else if (dRangeVal === "M") {
      valToTag("endDate", dBox, moment(startDate).endOf("month"));
    } else {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  const funcSetStartDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    if (dRangeVal === "D") {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  const btnSearch = async (d: any) => {
    const adminService = new AdminDataProvider();
    const startDate = _dev("startDate", dBox).option("value");
    const endDate = _dev("endDate", dBox).option("value");
    const adminId = _dev("adminId", tBox).option("value");
    const eventType = _dev("eventType", sBox).option("value");
    const offset = 0;
    const limit = 5000;

    const params = {
      offset: offset,
      limit: limit,
      adminId: adminId || undefined,
      eventType: eventType === "A" ? undefined : eventType,
      startDate: adminService.dateFormat(startDate),
      endDate: adminService.dateFormat(endDate),
    };

    try {
      const response = await adminService.getAdminLogs(params);
      setLogs(response.data.data);

      sessionStorage.setItem("h_daterangeState", dateRangeState);
      sessionStorage.setItem("h_startDate", startDate);
      sessionStorage.setItem("h_endDate", endDate);
      sessionStorage.setItem("h_endDateBoxState", endDateBoxState.toString());
      sessionStorage.setItem("h_adminId", adminId);
      sessionStorage.setItem("h_eventType", eventType);
    } catch (error) {
      console.error("Failed to fetch logs:", error);
    }
  };

  const onPageSizeChange = (e: any) => {
    const newPageSize = e.value;
    setCurrentPageSize(newPageSize);
    sessionStorage.setItem('history_page_size', newPageSize.toString());
  };

  const onPageIndexChange = (e: any) => {
    const newPageIndex = e.value;
    setCurrentPageIndex(newPageIndex);
    sessionStorage.setItem('history_page_index', newPageIndex.toString());
  };

  const openDisplayPopup = () => {
    setPopupVisibility(!isPopupVisible);
  };

  const renderContent = () => {
    return (
      <div>
        <CheckBox text="No" defaultValue={NoC} onValueChanged={(e) => setNoColumnState(e.value)} />
        <br />
        <CheckBox text="Admin ID" defaultValue={AdminIdC} onValueChanged={(e) => setAdminIdState(e.value)} style={{ marginTop: "10px" }} />
        <br />
        <CheckBox text="Event Type" defaultValue={EventTypeC} onValueChanged={(e) => setEventTypeState(e.value)} style={{ marginTop: "10px" }} />
        <br />
        <CheckBox text="Date" defaultValue={DateC} onValueChanged={(e) => setDateState(e.value)} style={{ marginTop: "10px" }} />
        <br />
        <CheckBox text="Description" defaultValue={DescriptionC} onValueChanged={(e) => setDescriptionState(e.value)} style={{ marginTop: "10px" }} />
      </div>
    );
  };

  return (
    <main id="content" className="content">
      <Popup
        width={250}
        height={300}
        title="Column Chooser"
        visible={isPopupVisible}
        contentRender={renderContent}
        onHiding={openDisplayPopup}
      />
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                </a>
              </li>
              <li className="current">
                <span>History</span>
              </li>
            </ul>
          </div>
        </header>

        <article className="reg-form insert" style={{ borderTop: "1px solid #0373c6" }}>
          <form action="" name="regForm">
            <fieldset>
              <article>
                <div className="cont" style={{ marginLeft: "10px" }}>
                  <div className="contline">
                    <SelectBox
                      id="daterange"
                      dataSource={dateRangeTypes}
                      defaultValue={dateRangeTypes[0]}
                      displayExpr="name"
                      onValueChanged={handleDateRangeChange}
                      valueExpr="value"
                      value={dateRangeState}
                      style={{ width: "160px", marginRight: "5px" }}
                    />
                    <DateBox
                      id="startDate"
                      className="prs-datebox"
                      displayFormat="yyyy-MM-dd"
                      onValueChanged={funcSetEndDate}
                      min={startMinDate}
                      max={startMaxDate}
                    />
                    <div style={{ paddingTop: "4px" }}>&#126;</div>
                    <DateBox
                      id="endDate"
                      className="prs-datebox"
                      displayFormat="yyyy-MM-dd"
                      disabled={endDateBoxState}
                      onValueChanged={funcSetStartDate}
                      min={endMinDate}
                      max={endMaxDate}
                    />
                  </div>
                  <div className="contline">
                    <TextBox
                      id="adminId"
                      placeholder="Admin ID"
                      value={adminId}
                      onValueChanged={(e) => setAdminId(e.value)}
                      style={{ width: "190px", marginRight: "10px" }}
                    />
                    <SelectBox
                      id="eventType"
                      dataSource={eventTypes}
                      displayExpr="name"
                      valueExpr="value"
                      value={eventType}
                      onValueChanged={(e) => setEventType(e.value)}
                      placeholder="Event Type"
                      style={{ width: "300px", marginRight: "10px" }}
                    />
                    <Button
                      style={{ marginRight: "10px" }}
                      text="(R)"
                      type="normal"
                      onClick={resetSearch}
                    />
                    <Button
                      width={50}
                      icon="fa fa-search"
                      type="normal"
                      onClick={btnSearch}
                    />
                  </div>
                </div>
              </article>
            </fieldset>
          </form>
        </article>
      </section>

      <section style={{ border: "1px solid #0373c6", marginTop: "10px", padding: "10px" }}>
        <header style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
          <h3 style={{ marginRight: "auto" }}>History List</h3>
          <Button text="Display" onClick={openDisplayPopup} />
        </header>

        <section className="sub-cont__wrap">
          <DataGrid
            id="gridCont1"
            dataSource={logs}
            columnHidingEnabled={true}
            noDataText="No data"
            selection={{ mode: "single" }}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            className="type-ct"
            showBorders
            onOptionChanged={(e) => {
              if (e.fullName === 'paging.pageSize') {
                onPageSizeChange(e);
              } else if (e.fullName === 'paging.pageIndex') {
                onPageIndexChange(e);
              }
            }}
          >
            <LoadPanel enabled={true} />
            <Column 
              caption="No" 
              width={50}
              cellRender={(e) => {
                const pageIndex = e.component.pageIndex();
                const pageSize = e.component.pageSize();
                const dataIndex = e.row.dataIndex;
                const calculatedNo = pageIndex * pageSize + dataIndex + 1;
                return calculatedNo;
              }}
            />
            <Column dataField="adminId" caption="Admin ID" />
            <Column dataField="eventType" caption="Event Type" />
            <Column 
              dataField="createdAt" 
              caption="Date"
              dataType="datetime"
              format="yyyy-MM-dd HH:mm:ss"
            />
            <Column dataField="details" caption="Description" />
            <Paging 
              enabled={true}
              defaultPageSize={currentPageSize}
              defaultPageIndex={currentPageIndex}
            />
            <Pager
              visible={true}
              allowedPageSizes={[10, 20, 50]}
              displayMode="full"
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        </section>
      </section>
    </main>
  );
};

export default History; 