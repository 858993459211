import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Axios 인스턴스 생성
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

// 응답 인터셉터 추가
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // 로컬 스토리지의 인증 관련 데이터 삭제
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      
      // 세션 스토리지의 인증 관련 데이터 삭제
      sessionStorage.clear();
      
      // window.location.href 대신 window.location.replace 사용
      window.location.replace('/auth/login');
    }
    return Promise.reject(error);
  }
);

// 요청 인터셉터 추가 (토큰 주입)
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance; 